
import { DailyWorkReportSearchRequest } from "@/models/dailyWorkReport/request/DailyWorkReportSearchRequest";
import Vue, { PropType } from "vue";
import {
  VCol,
  VRow,
  VBtn,
  VIcon,
  VTextField,
  VSpacer,
  VForm,
  VCard,
  VCardActions,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent
} from "vuetify/lib";
import DatePicker from "@/components/input/picker/DatePicker.vue";
import UserSelect from "@/components/input/select/UserSelect.vue";
import DailyWorkReportTableSelect from "@/components/input/select/DailyWorkReportTableSelect.vue";

export default Vue.extend({
  name: "DailyWorkReportCopyDialogForm",
  components: {
    DatePicker,
    UserSelect,
    DailyWorkReportTableSelect,
    VCol,
    VRow,
    VBtn,
    VIcon,
    VTextField,
    VSpacer,
    VForm,
    VCard,
    VCardActions,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent
  },
  props: {
    value: {
      type: Object as PropType<DailyWorkReportSearchRequest>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: 0
    };
  },
  computed: {
    inputValue: {
      get(): DailyWorkReportSearchRequest {
        return this.value;
      },
      set(newValue: DailyWorkReportSearchRequest) {
        this.$emit("input", newValue);
      }
    }
  }
});
