export class DailyWorkReportKey {
  workDate: string;
  serialNo: number | null;

  constructor(_props: { workDate: string; serialNo?: number | null }) {
    this.workDate = _props.workDate;
    if (_props.serialNo) {
      this.serialNo = _props.serialNo;
      return;
    }
    this.serialNo = null;
  }
}

export interface DailyWorkReportTimeStamp {
  createUser: string | null;
  createTs: number | null;
  updateUser: string | null;
  updateTs: number | null;
}
