
import Vue from "vue";

export default Vue.extend({
  name: "DataIteratorsFooter",
  props: {
    value: {
      type: Number,
      required: true
    },
    maxPage: {
      type: Number,
      required: true
    },
    form: {
      type: String,
      required: false,
      default: ""
    }
  },
  computed: {
    page: {
      get(): number {
        return this.value;
      },
      set(value: number) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    onClick() {
      this.$router.push({
        name: "DailyWorkReportSearch",
        params: { form: this.form }
      });
    }
  }
});
