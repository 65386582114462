
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "DataIteratorsBody",
  props: {
    items: {
      type: Array as PropType<any>,
      required: true
    },
    header: {
      type: Array as PropType<
        {
          text: string;
          value: string;
        }[]
      >,
      required: true
    },
    page: {
      type: Number
    },
    itemsPerPage: {
      type: Number
    }
  },
  computed: {
    isAuth: {
      get(): boolean {
        const userNippo = this.$store.state.departments;
        const webNippo = this.$store.state.dailyWorkReportStore.nippoType;
        const authority = this.$store.state.authority;
        return (
          (userNippo.includes(webNippo) && authority >= "5") ||
          authority === "9"
        );
      }
    }
  }
});
