
import Vue, { PropType } from "vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { PulldownResponse } from "@/models/response/PulldownResponse";
// Components
import { VCard, VIcon, VDataTable } from "vuetify/lib";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";

export default Vue.extend({
  name: "DailyWorkReportCopyDialogTable",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VCard,
    VIcon,
    VDataTable
  },
  props: {
    items: {
      type: Array as PropType<DailyWorkReportSearchResponse[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isError: false, //エラーフラグ
      errorMessage: [] as string[], //エラーメッセージ
      userItems: [] as PulldownResponse[],
      header: [
        {
          text: "日付",
          value: "workDate"
        },
        {
          text: "日報種類",
          value: "tableName"
        },
        {
          text: "通番",
          value: "index"
        },
        {
          text: "作業者",
          value: "userId"
        },
        {
          text: "受注番号",
          value: "acceptNo"
        },
        {
          text: "品名",
          value: "itemName"
        },
        {
          text: "サイズ",
          value: "size"
        },
        {
          text: "仕様書",
          value: "specificationNo"
        },
        {
          text: "コピー",
          value: "copy"
        }
      ]
    };
  },
  computed: {
    isLoading: {
      get(): boolean {
        return this.loading;
      },
      set(value: boolean) {
        this.$emit("update:loading", value);
      }
    }
  },
  methods: {
    onCopy(value: DailyWorkReportSearchResponse) {
      this.$emit("copy", value);
    },
    convertUserName(userId: string | null) {
      const user = this.userItems.find(v => v.code === userId);
      return user ? user.name : "";
    },
    /**
     * データベースからユーザデータ取得
     */
    async getUser() {
      console.log("getUser start");
      this.isLoading = true;
      const api = new Api();
      try {
        const path = api.Paths.shainPulldown;
        this.userItems = await api.$get<PulldownResponse[]>(path);
      } catch (e) {
        this.isError = true;
        if (e instanceof Error) {
          this.errorMessage.push(e.message);
        } else {
          this.errorMessage.push("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
        console.log("getUser end");
      }
    }
  },
  async mounted() {
    await this.getUser();
  }
});
