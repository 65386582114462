
import Vue, { PropType } from "vue";

// Components
import { VDataIterator, VCard } from "vuetify/lib";
import DataIteratorsHeader from "@/components/table/dailyworkreport/dataiterators/DataIteratorsHeader.vue";
import DataIteratorsBody from "@/components/table/dailyworkreport/dataiterators/DataIteratorsBody.vue";
import DataIteratorsFooter from "@/components/table/dailyworkreport/dataiterators/DataIteratorsFooter.vue";

export default Vue.extend({
  name: "DataIterators",
  components: {
    VDataIterator,
    VCard,
    DataIteratorsHeader,
    DataIteratorsBody,
    DataIteratorsFooter
  },
  props: {
    value: {
      type: String,
      required: true
    },
    items: {
      type: Array as PropType<any>,
      required: true
    },
    header: {
      type: Array as PropType<
        {
          text: string;
          value: string;
        }[]
      >,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: "タイトル"
    },
    form: {
      type: String,
      required: false,
      default: null
    }
  },
  data() {
    return {
      page: 1
    };
  },
  computed: {
    inputValue: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit("input", value);
      }
    },
    itemsPerPage: {
      get(): number {
        switch (this.$vuetify.breakpoint.name) {
          case "xl":
            return 4;
          case "lg":
            return 4;
          case "md":
            return 3;
          case "sm":
            return 2;
          case "xs":
            return 2;
          default:
            return 3;
        }
      },
      set() {
        console.log();
      }
    },
    numberOfPages: {
      get(): number {
        return Math.ceil(this.items.length / this.itemsPerPage);
      }
    }
  },
  methods: {
    onView(index: number) {
      this.$emit("view", index);
    },
    onEdit(index: number) {
      this.$emit("edit", index);
    },
    onDelete(index: number) {
      this.$emit("delete", index);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    }
  }
});
