
import moment from "moment";
import Vue from "vue";

//class
import { DATE_FORMAT } from "@/defines";

// Components
import { DailyWorkReportSearchRequest } from "@/models/dailyWorkReport/request/DailyWorkReportSearchRequest";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";

// mixins
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import DailyWorkReportCopyDialogForm from "../copy/DailyWorkReportCopyDialogForm.vue";
import DailyWorkReportCopyDialogTable from "../copy/DailyWorkReportCopyDialogTable.vue";

const DATE_FROM = moment()
  .subtract(1, "week")
  .format(DATE_FORMAT.DATE);
const DATE_TO = moment().format(DATE_FORMAT.DATE);

export default Vue.extend({
  name: "DailyWorkReportCopyDialog",
  components: {
    DailyWorkReportCopyDialogForm,
    DailyWorkReportCopyDialogTable
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      title: "コピー元検索",
      records: [] as DailyWorkReportSearchResponse[],
      form: new DailyWorkReportSearchRequest({
        workDateFrom: DATE_FROM,
        workDateTo: DATE_TO,
        userId: null,
        itemName: null,
        tableName: null,
        acceptNo: null,
        size: null,
        specificationNo: null
      }),
      isLoading: false
    };
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    async onClick() {
      console.log("DailyWorkReportSearchDialog onClick start");
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        const param = this.form;
        const result = await api.$post<DailyWorkReportSearchResponse[]>(
          api.Paths.dailyWorkReport.search,
          param
        );
        if (result) {
          this.records = result;
        }
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        console.log("DailyWorkReportSearchDialog onClick end");
        this.isLoading = false;
      }
    },
    async getTable() {
      const showDialogs = new ShowDialogs();
      try {
        const api = new Api();
        const result = await api.$get<any>(
          api.Paths.dailyWorkReport.tableCode,
          "query=" + this.$store.state.dailyWorkReportStore.nippoType
        );
        if (result) {
          this.form.tableName = result.value;
        }
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      }
    },
    onCopy(value: DailyWorkReportSearchResponse) {
      this.$emit("copy", value);
    }
  },
  async mounted() {
    await this.getTable();
    await this.onClick();
  }
});
