export class DailyWorkReportSearchRequest {
  workDateFrom: string | null;
  workDateTo: string | null;
  userId: string | null;
  itemName: string | null;
  tableName: string | null;
  acceptNo: string | null;
  size: string | null;
  specificationNo: string | null;

  constructor(props?: {
    workDateFrom: string | null;
    workDateTo: string | null;
    userId: string | null;
    itemName: string | null;
    tableName: string | null;
    acceptNo: string | null;
    size: string | null;
    specificationNo: string | null;
  }) {
    if (props) {
      this.workDateFrom = props.workDateFrom;
      this.workDateTo = props.workDateTo;
      this.userId = props.userId;
      this.itemName = props.itemName;
      this.tableName = props.tableName;
      this.acceptNo = props.acceptNo;
      this.size = props.size;
      this.specificationNo = props.specificationNo;
      return;
    }
    this.workDateFrom = null;
    this.workDateTo = null;
    this.userId = null;
    this.itemName = null;
    this.tableName = null;
    this.acceptNo = null;
    this.size = null;
    this.specificationNo = null;
  }
}
