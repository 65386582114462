
import Vue, { PropType } from "vue";
import DailyWorkReportInput, {
  Headers
} from "@/components/input/DailyWorkReportInput.vue";
import { VDialog, VCard, VCardText, VSnackbar, VBtn, VIcon } from "vuetify/lib";
import DailyWorkReportCopyDialog from "./copy/DailyWorkReportCopyDialog.vue";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";
import { OrderDetailsSearchResponse } from "@/models/orderDetails/response/OrderDetailsSearchResponse";
import ShowDialogs from "@/mixins/showDialogs";
import OrderDetailsSearchDialog from "./order/OrderDetailsSearchDialog.vue";
import { OrderDetailsSearchRequest } from "@/models/orderDetails/request/OrderDetailsSearchRequest";
import Api from "@/mixins/api";

interface VForm extends Vue {
  reset(): void;
  resetValidation(): void;
  validate(): boolean;
}

export default Vue.extend({
  name: "DailyWorkReportFormDialog",
  components: {
    VDialog,
    VCard,
    VCardText,
    DailyWorkReportInput,
    VSnackbar,
    VBtn,
    VIcon,
    DailyWorkReportCopyDialog,
    OrderDetailsSearchDialog
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: "タイトル"
    },
    tableName: {
      type: String,
      required: true,
      default: "テーブル名"
    },
    items: {
      type: Object as PropType<any>,
      required: true
    },
    headers: {
      type: Array as PropType<Headers[]>,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    },
    copy: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      snackbar: false,
      snackbarText: "",
      isSearchDialog: false,
      isOrderSearchDialog: false,
      changeCount: 0,
      isLoading: false,
      orderDetailsSearchRequest: new OrderDetailsSearchRequest(),
      records: [] as OrderDetailsSearchResponse[],
      acceptNoPrefix: "acceptNoPrefix"
    };
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    },
    propItems: {
      get(): any {
        return this.items;
      },
      set(newValue: any) {
        this.$emit("input", newValue);
      }
    },
    isAuth: {
      get(): boolean {
        const userNippo = this.$store.state.departments;
        const webNippo = this.$store.state.dailyWorkReportStore.nippoType;
        const authority = this.$store.state.authority;
        return !userNippo.includes(webNippo) && authority !== "9";
      }
    }
  },
  watch: {
    propItems: {
      handler(newValue: any, oldValue: any) {
        console.log("change items", newValue, oldValue);
        if (!newValue.acceptNo) {
          newValue.acceptNo = null;
          //newValue.specificationNo = null;
          //newValue.itemName = null;
          //newValue.size = null;
          newValue.denno = null;
          newValue.gyono = null;
        }
        this.changeCount++;
      },
      deep: true
    }
  },
  methods: {
    async onClose() {
      if (this.changeCount > 1) {
        const showDialogs = new ShowDialogs();
        const title = "確認";
        const message = "変更は破棄されます。\nよろしいですか？";
        const confirm = await showDialogs.$confirm(message, title);
        if (!confirm) return;
      }
      this.inputValue = false;
      this.changeCount = 0;
    },
    onClick() {
      const form = this.$refs.form as VForm;
      if (!form.validate()) {
        this.snackbarText =
          "入力に誤りがあります。入力内容を確認してください。";
        this.snackbar = true;
        return;
      }
      /*
      if (this.propItems.successFalg == "1" && !this.propItems.acceptNo) {
        this.snackbarText =
          "受注番号品 完了がチェックされています。受注番号を入力してください。";
        this.snackbar = true;
        return;
      }
      if (this.propItems.successFlag == "1" && !this.propItems.acceptNo) {
        this.snackbarText =
          "受注番号品 完了がチェックされています。受注番号を入力してください。";
        this.snackbar = true;
        return;
      }*/
      this.$emit("save", this.propItems);
    },
    onCopy(value: DailyWorkReportSearchResponse) {
      const close = () => {
        this.isSearchDialog = false;
      };
      this.$emit("copy", value, close);
    },
    onSelect(value: OrderDetailsSearchResponse) {
      const close = () => {
        this.isOrderSearchDialog = false;
      };
      this.$emit("find", value, close);
    },
    genAcceptNo(searchText: string, searchPrefix: string) {
      console.log("order no search", searchPrefix, searchText);
      const suffixLength = 5;
      const suffix = searchText ? searchText.slice(-suffixLength) : "";
      const possibleSuffix = Array(suffixLength)
        .fill("0")
        .map((_, index) => index + 1);
      const autoDigitSize = possibleSuffix.find(item => item == suffix.length);
      const finalSearchText =
        (searchPrefix ?? "00") +
        Array(suffixLength - (autoDigitSize ?? 0))
          .fill("0")
          .join("") +
        suffix;
      return finalSearchText;
    },
    async onFind(searchText: string, searchPrefix: string) {
      const showDialogs = new ShowDialogs();
      this.isLoading = true;
      try {
        const api = new Api();
        this.orderDetailsSearchRequest.juhno = this.genAcceptNo(
          searchText,
          searchPrefix
        );
        this.orderDetailsSearchRequest.tableName = this.tableName;
        const param = this.orderDetailsSearchRequest;
        const result = await api.$post<OrderDetailsSearchResponse[]>(
          api.Paths.orderDetails.search,
          param
        );
        if (result.length > 1) {
          this.records = result;
          this.isOrderSearchDialog = true;
        } else if (result.length == 1) {
          this.$emit("find", result[0], close);
        } else
          this.$emit(
            "find",
            {
              juhno: null,
              syno: null,
              shonm: null,
              kikaku: null,
              denno: null,
              gyono: null,
              alreadyAssigned: null
            },
            close
          );
      } catch (e) {
        if (e instanceof Error) {
          showDialogs.$error(e.message);
        } else {
          showDialogs.$error("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
      }
    }
  }
});
