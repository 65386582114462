
import Vue from "vue";

export default Vue.extend({
  name: "Overlay",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      }
    }
  }
});
