
import Vue, { PropType } from "vue";
import {
  VTextField,
  VTextarea,
  VSelect,
  VCheckbox,
  VAutocomplete,
  VCombobox
} from "vuetify/lib";
import CheckboxGroup from "@/components/input/CheckboxGroup.vue";
import DatePicker from "@/components/input/picker/DatePicker.vue";
import SearchButton from "@/components/input/button/SearchButton.vue";
import { DailyWorkReportSearchResponse } from "@/models/dailyWorkReport/response/DailyWorkReportSearchResponse";

export const DailyWorkReportInput = {
  text: "text",
  textarea: "textarea",
  number: "number",
  select: "select",
  checkbox: "checkbox",
  "checkbox-group": "checkbox-group",
  autocomplete: "autocomplete",
  combobox: "combobox",
  "date-picker": "date-picker"
} as const;
export type DailyWorkReportInputType = typeof DailyWorkReportInput[keyof typeof DailyWorkReportInput];

export const InputRules = {
  required: (val: unknown) => !!val || "必須項目です",
  maxLenght: (length: number) => {
    return (val: unknown) => {
      if (typeof val === "string") {
        return val.length <= length || `${length}文字以内で入力してください`;
      }
      return true;
    };
  },
  digit: (digit: number) => {
    return (val: unknown) => {
      if (typeof val !== "number" && val !== "")
        return "数値を入力してください";
      const numbers = val.toString().split(".");
      if (numbers[0].length > digit) return `${digit}桁以内で入力してください`;
      return true;
    };
  },
  scale: (scale: number) => {
    return (val: unknown) => {
      if (typeof val !== "number" && val !== "")
        return "数値を入力してください";
      const numbers = val.toString().split(".");
      if (numbers[1]?.length > scale)
        return `小数は${scale}以内で入力してください`;
      return true;
    };
  },
  decimal: (digit: unknown, scale: unknown) => {
    return (val: unknown) => {
      if (typeof val !== "number" && val !== "") return true;
      if (typeof digit === "number" && typeof scale === "number") {
        const numbers = val.toString().split(".");
        if (numbers[0].length > digit - scale)
          return `${digit - scale}桁以内で入力してください`;
        if (numbers[1]?.length > scale)
          return `小数は${scale}以内で入力してください`;
      } else if (typeof digit === "number") {
        const numbers = val.toString().split(".");
        if (numbers[0].length > digit)
          return `${digit}桁以内で入力してください`;
      } else if (typeof scale === "number") {
        const numbers = val.toString().split(".");
        if (numbers[1]?.length > scale)
          return `小数は${scale}以内で入力してください`;
      }
      return true;
    };
  }
} as const;

export type Headers = {
  text: string;
  value: string;
  type: DailyWorkReportInputType;
  items?: {
    text: string;
    value: string;
  }[];
  required?: boolean;
  maxLength?: number;
  digit?: number;
  scale?: number;
  default?: number | string | boolean | null;
  disabled?: boolean;
  readonly?: boolean;
  linkage?: boolean;
  backgroundColor?: string;
  hint?: string;
  persistentHint?: boolean;
  search?: boolean;
  cols?: number | string;
};

export default Vue.extend({
  name: "DailyWorkReportInput",
  components: {
    CheckboxGroup,
    VTextField,
    VTextarea,
    VSelect,
    VCheckbox,
    VAutocomplete,
    VCombobox,
    DatePicker
  },
  data() {
    return {
      rules: [] as Function[],
      InputRules: InputRules,
      isLoading: false,
      isSearchDialog: false,
      records: [] as DailyWorkReportSearchResponse[]
    };
  },
  props: {
    value: {
      type: undefined
    },
    type: {
      type: String as PropType<DailyWorkReportInputType>
    },
    label: {
      type: String,
      required: false,
      default: "ラベル"
    },
    items: {
      type: Array as PropType<unknown[]>,
      required: false,
      default: () => []
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    maxLength: {
      type: Number,
      required: false,
      default: null
    },
    digit: {
      type: Number,
      required: false,
      default: null
    },
    scale: {
      type: Number,
      required: false,
      default: null
    },
    default: {
      type: [Number, String, Boolean],
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null
    },
    hint: {
      type: String,
      required: false,
      default: null
    },
    persistentHint: {
      type: Boolean,
      required: false,
      default: false
    },
    search: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    inputValue: {
      get(): unknown {
        if (this.default) {
          this.$emit("input", this.default);
          return this.default;
        }
        return this.value;
      },
      set(newValue: unknown) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    onFind(value: boolean) {
      if (value) this.$emit("find");
    }
  },
  mounted() {
    if (this.required) this.rules.push(InputRules.required);
    if (this.maxLength) this.rules.push(InputRules.maxLenght(this.maxLength));
    if (this.digit || this.scale) {
      this.rules.push(InputRules.decimal(this.digit, this.scale));
    }
  }
});
