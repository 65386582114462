
import Vue from "vue";

export default Vue.extend({
  name: "Snackbar",
  components: {},
  props: {
    value: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: true
    }
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean) {
        this.$emit("input", value);
      }
    }
  }
});
