
import Vue, { PropType } from "vue";

// Components
import { OrderDetailsSearchRequest } from "@/models/orderDetails/request/OrderDetailsSearchRequest";
import { OrderDetailsSearchResponse } from "@/models/orderDetails/response/OrderDetailsSearchResponse";
import OrderDetailsSearchForm from "../order/OrderDetailsSearchForm.vue";
import OrderDetailsTable from "../order/OrderDetailsTable.vue";

export default Vue.extend({
  name: "OrderDetailsSearchDialog",
  components: {
    OrderDetailsSearchForm,
    OrderDetailsTable
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    form: {
      type: Object as PropType<OrderDetailsSearchRequest>,
      required: true
    },
    records: {
      type: Array as PropType<OrderDetailsSearchResponse[]>,
      required: true
    }
  },
  data() {
    return {
      title: "受注明細検索",
      isLoading: false
    };
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    }
  },
  methods: {
    onSelect(value: OrderDetailsSearchResponse) {
      this.$emit("select", value);
    }
  }
});
