
import { OrderDetailsSearchRequest } from "@/models/orderDetails/request/OrderDetailsSearchRequest";
import Vue, { PropType } from "vue";
import {
  VCol,
  VRow,
  VTextField,
  VForm,
  VCard,
  VExpansionPanels,
  VExpansionPanel,
  VExpansionPanelHeader,
  VExpansionPanelContent
} from "vuetify/lib";

export default Vue.extend({
  name: "OrderDetailsSearchForm",
  components: {
    VCol,
    VRow,
    VTextField,
    VForm,
    VCard,
    VExpansionPanels,
    VExpansionPanel,
    VExpansionPanelHeader,
    VExpansionPanelContent
  },
  props: {
    value: {
      type: Object as PropType<OrderDetailsSearchRequest>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isOpen: 0
    };
  },
  computed: {
    inputValue: {
      get(): OrderDetailsSearchRequest {
        return this.value;
      },
      set(newValue: OrderDetailsSearchRequest) {
        this.$emit("input", newValue);
      }
    }
  }
});
