
import Vue, { PropType } from "vue";

// mixins
import Common from "@/mixins/common";
import ShowDialogs from "@/mixins/showDialogs";
import Api from "@/mixins/api";
import { PulldownResponse } from "@/models/response/PulldownResponse";
// Components
import { VCard, VIcon, VDataTable } from "vuetify/lib";
import { OrderDetailsSearchResponse } from "@/models/orderDetails/response/OrderDetailsSearchResponse";

export default Vue.extend({
  name: "OrderDetailsTable",
  mixins: [Common, ShowDialogs, Api],
  components: {
    VCard,
    VIcon,
    VDataTable
  },
  props: {
    items: {
      type: Array as PropType<OrderDetailsSearchResponse[]>,
      required: true
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isError: false, //エラーフラグ
      errorMessage: [] as string[], //エラーメッセージ
      userItems: [] as PulldownResponse[],
      header: [
        {
          text: "商品コード",
          value: "shocd"
        },
        {
          text: "商品名",
          value: "shonm"
        },
        {
          text: "サイズ",
          value: "kikaku"
        },
        {
          text: "仕様No",
          value: "syno"
        },
        {
          text: "引用",
          value: "alreadyAssigned"
        },
        {
          text: "選択",
          value: "select"
        }
      ]
    };
  },
  computed: {
    isLoading: {
      get(): boolean {
        return this.loading;
      },
      set(value: boolean) {
        this.$emit("update:loading", value);
      }
    }
  },
  methods: {
    onSelect(value: OrderDetailsSearchResponse) {
      this.$emit("select", value);
    }
  }
});
