
import { PulldownResponse } from "@/models/response/PulldownResponse";
import Vue, { PropType } from "vue";

export type CheckboxGroupItems = {
  text: string;
  value: string | number | boolean | null;
};

/**
 * コードマスタのデータを整形
 * @param pulldown
 * @param code
 */
export const pulldownToCheckItems = (
  pulldown: PulldownResponse[]
): CheckboxGroupItems[] => {
  return pulldown.map(e => ({ text: e.name, value: e.code }));
};

export default Vue.extend({
  name: "checkbox-group",
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: null
    },
    items: {
      type: Array as PropType<Array<CheckboxGroupItems>>
    },
    label: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    inputedValue: {
      get(): number | null {
        const selectKey = this.items.map(e => e.value).indexOf(this.value);
        if (selectKey === -1) {
          return null;
        } else {
          return selectKey;
        }
      },
      set(key: number | null) {
        if (key || key === 0) {
          this.$emit("input", this.items[key].value);
        } else {
          this.$emit("input", null);
        }
      }
    }
  }
});
