
import Vue from "vue";
import DatePicker from "@/components/input/picker/DatePicker.vue";

export default Vue.extend({
  name: "DataIteratorsHeader",
  components: { DatePicker },
  props: {
    value: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: false,
      default: "タイトル"
    }
  },
  computed: {
    inputValue: {
      get(): string {
        return this.value;
      },
      set(value: string) {
        this.$emit("input", value);
      }
    },
    isAuth: {
      get(): boolean {
        const userNippo = this.$store.state.departments;
        const webNippo = this.$store.state.dailyWorkReportStore.nippoType;
        const authority = this.$store.state.authority;
        return !userNippo.includes(webNippo) && authority !== "9";
      }
    }
  }
});
