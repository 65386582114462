
import moment from "moment";
import Vue from "vue";
import { VMenu, VTextField } from "vuetify/lib";

export default Vue.extend({
  name: "DatePicker",
  components: {
    VMenu,
    VTextField
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false
    },
    outlined: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: "日付選択"
    },
    format: {
      type: String,
      required: false,
      default: "YYYY年MM月DD日"
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    flat: {
      type: Boolean,
      required: false,
      default: false
    },
    soloInverted: {
      type: Boolean,
      required: false,
      default: false
    },
    prependInnerIcon: {
      type: String,
      required: false,
      default: undefined
    },
    filled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isActive: false,
      isDisabled: false
    };
  },
  computed: {
    inputValue: {
      get(): string | null {
        return this.value;
      },
      set(value: string | null) {
        this.$emit("input", value);
      }
    },
    formatValue: {
      get(): string | null {
        if (!this.inputValue) return null;
        return moment(this.inputValue).format(this.format);
      }
    }
  },
  methods: {
    formatDate(date: string) {
      return new Date(date).getDate();
    },
    onClear() {
      this.inputValue = null;
    }
  }
});
