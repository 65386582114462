
import Vue from "vue";

export default Vue.extend({
  name: "SpecDocsDialog",
  props: {
    value: {
      type: Boolean,
      required: true
    },
    fileUrl: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      title: "仕様書閲覧",
      isLoading: false
    };
  },
  computed: {
    inputValue: {
      get(): boolean {
        return this.value;
      },
      set(newValue: boolean) {
        this.$emit("input", newValue);
      }
    }
  },
  created() {
    console.log("here", this.fileUrl, this.inputValue);
  }
});
