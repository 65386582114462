export class OrderDetailsSearchRequest {
  juhno: string | null;
  tableName: string | null;
  constructor(props?: { juhno: string | null; tableName: string | null }) {
    if (props) {
      this.juhno = props.juhno;
      this.tableName = props.tableName;
      return;
    }
    this.juhno = null;
    this.tableName = null;
  }
}
