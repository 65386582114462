export class ShainSearchRequest {
  userId: string | null;
  userName: string | null;
  department: string[] | null;
  password: string | null;
  authority: string | null;
  isValid: string | null;
  constructor(props?: {
    userId?: string | null;
    userName?: string | null;
    department?: string[];
    password?: string | null;
    authority?: string | null;
    isValid?: string | null;
  }) {
    if (props) {
      this.userId = props.userId ? props.userId : null;
      this.userName = props.userName ? props.userName : null;
      this.department = props.department ? props.department : null;
      this.password = props.password ? props.password : null;
      this.authority = props.authority ? props.authority : null;
      this.isValid = props.isValid ? props.isValid : null;
      return;
    }
    this.userId = null;
    this.userName = null;
    this.department = null;
    this.password = null;
    this.authority = null;
    this.isValid = null;
  }
}
