
import Vue from "vue";
//class
import { PulldownResponse } from "@/models/response/PulldownResponse";
// mixins
import Api from "@/mixins/api";
// Components
import { VSelect } from "vuetify/lib";

export default Vue.extend({
  name: "UserSelect",
  components: {
    VSelect
  },
  props: {
    value: {
      type: String,
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: "作業者"
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    hideDetails: {
      type: Boolean,
      required: false,
      default: false
    },
    dense: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isLoading: true, //読み込みフラグ
      isError: false, //エラーフラグ
      errorMessage: [] as string[], //エラーメッセージ
      selectItems: [] as PulldownResponse[] //ユーザデータ
    };
  },
  computed: {
    propsValue: {
      get(): string | null {
        return this.value;
      },
      set(value: string | null) {
        this.$emit("input", value);
      }
    }
  },
  methods: {
    /**
     * データベースからユーザデータ取得
     */
    async getUser() {
      console.log("getUser start");
      this.isLoading = true;
      const api = new Api();
      try {
        const path = api.Paths.shainPulldown;
        this.selectItems = await api.$get<PulldownResponse[]>(path);
      } catch (e) {
        this.isError = true;
        if (e instanceof Error) {
          this.errorMessage.push(e.message);
        } else {
          this.errorMessage.push("予期せぬエラーが発生しました");
        }
      } finally {
        this.isLoading = false;
        console.log("getUser end");
      }
    }
  },
  async mounted() {
    console.log("UserSelect mounted start");
    await this.getUser();
    console.log("UserSelect mounted end");
  }
});
